import { Component, OnInit } from '@angular/core';


// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'app-product-items',
  templateUrl: './product-items.component.html',
  styleUrls: ['./product-items.component.scss'],
})


export class ProductItemsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
