import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductItemsComponent } from './components/product-items/product-items.component';
import { EntradaComponent } from './components/entrada/entrada.component';



@NgModule({
  declarations: [
    AppComponent,
    ProductItemsComponent,
    EntradaComponent,
     ],

  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule
     ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
